/** @format */

import { Account } from '@common/model/apiClient/Account';
import TabularAutocompleteOption from '@templates/Document/styled/TabularAutocompleteOption';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface AAOProps {
  account: Account;
}

function AccountAutocompleteOption({ account }: AAOProps) {
  return (
    <TabularAutocompleteOption>
      <div>
        <FormattedMessage defaultMessage={'Name'} description={'Site Name label'} />:
      </div>
      <div>{account.name}</div>
      <div>
        <FormattedMessage defaultMessage={'Account Number'} description={'Home page table account number header'} />:
      </div>
      <div>{account.accountNumber}</div>
    </TabularAutocompleteOption>
  );
}

export default AccountAutocompleteOption;
