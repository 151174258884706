/** @format */
import React, { ReactNode, useImperativeHandle, useRef } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

/* eslint-disable-next-line */
export interface ScrollbarProps {
  children: ReactNode;
  disableXScroll?: boolean;
  disableYScroll?: boolean;
  wheelPropagation?: boolean;
  id?: string;
}

export const Scrollbar = React.forwardRef<HTMLElement, ScrollbarProps>((props, ref) => {
  const container = useRef<HTMLElement>();

  useImperativeHandle(
    ref,
    () => {
      return container.current;
    },
    [],
  );

  return (
    <PerfectScrollbar
      containerRef={(containerRef) => (container.current = containerRef)}
      options={{ suppressScrollX: props.disableXScroll, suppressScrollY: props.disableYScroll, wheelPropagation: props.wheelPropagation }}
      id={props.id}
    >
      {props.children}
    </PerfectScrollbar>
  );
});

Scrollbar.displayName = 'Scrollbar';

export default Scrollbar;
