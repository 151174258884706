/** @format */

import { Resource } from '@common/application/enums/Resource';
import { ResourceUrl } from '@common/application/helpers/generateSignedUrlsForResults';
import uploadFileToSignedUrl from '@common/application/helpers/uploadFileToSignedUrl';
import { Account } from '@common/model/apiClient/Account';
import { Document } from '@common/model/apiClient/Document';
import { User } from '@common/model/apiClient/User';
import axios from 'axios';
import { Branch } from './Branch';

export class Company {
  id;
  logo: ResourceUrl;
  name;
  realm;
  createdAt;
  updatedAt;
  companyDocumentStyle;
  deletedAt;

  constructor(name, realm, logo) {
    this.name = name;
    this.realm = realm;
    this.logo = logo;
  }

  static list(
    query,
    sort,
    queryOpts: Record<string, unknown>,
    size?: number,
    pit?: { id: string },
    search_after?: Array<unknown>,
  ): Promise<{ payload: Array<{ _source: Company; sort }>; last_page: number; total: { value: number } }> {
    // Ensure this code is not ran by SSR!
    if (typeof window !== 'undefined') {
      return axios
        .get(`${window.location.protocol}//${window.location.host}/api/v1/companies`, {
          params: {
            sequence_id: 1,
            pit: pit,
            query: query,
            queryOpts: JSON.stringify(queryOpts ?? { deleted: false }),
            sort: JSON.stringify(sort),
            search_after: JSON.stringify(search_after),
            size: size,
          },
        })
        .then((res) => ({ ...res.data }));
    }
    throw new Error();
  }

  static autocomplete(query, config) {
    if (typeof window !== 'undefined') {
      return axios
        .get(`${window.location.protocol}//${window.location.host}/api/v1/companies/search`, {
          params: {
            sequence_id: 1,
            query,
          },
        })
        .then((res) => ({ ...res.data }));
    }
    throw new Error();
  }

  static getById(companyId): Promise<{ message: string; paginated: boolean; payload: Company }> {
    return axios.get(`${window.location.protocol}//${window.location.host}/api/v1/companies/${companyId}`).then((res) => ({ ...res.data }));
  }

  static get(): Promise<{ message: string; paginated: boolean; payload: Company }> {
    return axios.get(`${window.location.protocol}//${window.location.host}/api/v1/companies/this`).then((res) => ({ ...res.data }));
  }

  static delete(id: number): Promise<Record<string, unknown>> {
    return axios.delete(`${window.location.protocol}//${window.location.host}/api/v1/companies/${id}`).then((res) => ({ ...res.data }));
  }

  static undelete(id: number): Promise<Record<string, unknown>> {
    return axios.post(`${window.location.protocol}//${window.location.host}/api/v1/companies/${id}/undelete`).then((res) => ({ ...res.data }));
  }

  static getDocTotals(timeSpan: { start: Date; end: Date }): Promise<{ message: string; paginated: boolean; payload: { totalCompanySavings: string; documentCount: string } }> {
    return axios
      .get(`${window.location.protocol}//${window.location.host}/api/v1/companies/totals`, { params: { start: timeSpan.start, end: timeSpan.end } })
      .then((res) => ({ ...res.data }));
  }

  static getDashboardStats(): Promise<{
    message: string;
    paginated: boolean;
    payload: { unapprovedContactCount: number; unapprovedIndustryCount: number; missingAccountLogoCount: number; recentDocuments: Array<Document> };
  }> {
    return axios.get(`${window.location.protocol}//${window.location.host}/api/v1/companies/dashboard`).then((res) => ({ ...res.data }));
  }
  static getRankings(timeSpan: { startDate: Date; endDate: Date }): Promise<{
    message: string;
    paginated: boolean;
    payload: {
      topAccounts: Array<Account>;
      topBranches: Array<Branch>;
      topUsers: Array<{ total_savings: { value: number }; author: { hits: Array<{ _source: User }> } }>;
    };
  }> {
    return axios
      .get(`${window.location.protocol}//${window.location.host}/api/v1/companies/rankings`, { params: { start: timeSpan.startDate, end: timeSpan.endDate } })
      .then((res) => ({ ...res.data }));
  }
  static getRecentDocuments(): Promise<{
    message: string;
    paginated: boolean;
    payload: {
      recentDocuments: Array<{ _source: Document; _id: string }>;
    };
  }> {
    return axios.get(`${window.location.protocol}//${window.location.host}/api/v1/companies/recentdocuments`).then((res) => ({ ...res.data }));
  }

  static getAddTemplate(): Promise<Record<string, unknown>> {
    return axios.get(`${window.location.protocol}//${window.location.host}/api/v1/templates/companies/add`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  add(fileName: string, fileExt: string): Promise<Record<string, unknown>> {
    return axios
      .post(`${window.location.protocol}//${window.location.host}/api/v1/companies`, {
        payload: {
          name: this.name,
          realm: this.realm,
          logo: { dataPresent: !!this.logo, ext: fileExt, name: fileName },
        },
      })
      .then(async (res) => {
        const { signedUrl } = res.data.payload;
        await uploadFileToSignedUrl(signedUrl, this.logo.url as File, res.data.payload, 'logo', Resource.COMPANY_LOGO);
        return { ...res.data };
      });
  }

  static addIdToAuthors() {
    return axios.post(`${window.location.protocol}//${window.location.host}/api/v1/documents/updateUserIds`, {
      payload: {
        // name: this.name,
        // realm: this.realm,
        // logo: { dataPresent: !!this.logo, ext: fileExt, name: fileName },
      },
    });
  }

  static update(updatedCompany: { name: string; realm: string; logo: File; companyDocumentStyle?: Record<string, any> }, id: number): Promise<Record<string, unknown>> {
    return axios
      .patch(`${window.location.protocol}//${window.location.host}/api/v1/companies/${id}`, { payload: { ...updatedCompany, logo: !!updatedCompany.logo } })
      .then(async (res) => {
        const { signedUrl } = res.data.payload;
        if (signedUrl) {
          await uploadFileToSignedUrl(signedUrl, updatedCompany.logo as File, res.data.payload, 'logo', Resource.COMPANY_LOGO);
        }
        return { ...res.data };
      });
  }
}
