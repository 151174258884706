/** @format */

import Select, { ISelectOption } from '@atoms/Select';
import useSecureQuery from '@common/application/auth/useSecureQuery';
import { CountryClient } from '@common/model/apiClient';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import React, { useCallback, useMemo, useState } from 'react';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';
import { FormattedMessage } from 'react-intl';
import { Item, Section } from 'react-stately';

interface LocaleDataObject {
  bcp47: string;
  countryId: string;
  defaultCurrencyId: string;
  description: string;
  microsoftLanguageCode: number;
  nativeDescription: string;
}

interface LocaleSelectProps {
  countryAlphaCodes: string[];
  localesData: LocaleDataObject[];
  onChange?: (value: ISelectOption) => void;
}

const CountrySection = styled.span`
  display: inline-flex;
  //height: fit-content;
  align-items: center;
  gap: 6px;
  padding: 2px;
`;

function LocaleSelect(props: LocaleSelectProps) {
  const [countryLocales, setCountryLocales] = useState<Array<{ id: string; options: Array<{ id: string; name: string }> }>>([]);
  const [defaultKey, setDefaultKey] = useState<string>();
  const router = useRouter();

  useSecureQuery(
    ['countries_supported_not_deleted', props.countryAlphaCodes],
    () => CountryClient.list('', [], { filters: [{ terms: { 'alpha2.keyword': props.countryAlphaCodes } }] }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      enabled: !!props.countryAlphaCodes.length,
      onSuccess: (data) => {
        const localeArray = data?.payload?.map?.((country) => {
          const c = country._source;
          return {
            id: c.alpha2,
            options: props.localesData
              .filter((locale) => locale.countryId === c.alpha2)
              .map((l) => ({
                id: l.bcp47,
                // type: 'option',
                name: l.nativeDescription ?? l.description,
              })),
          };
        });

        const key = localeArray.find?.((country) => country.id === router.locale.substring(3, 5))?.options?.find?.((opt) => opt.id === router.locale);
        setDefaultKey(key?.id);
        setCountryLocales(localeArray);
      },
    },
  );

  const locale = useMemo(
    () => countryLocales?.find?.((country) => country.id === router.locale.substring(3, 5))?.options?.find?.((opt) => opt.id === router.locale),
    [router.locale, countryLocales],
  );

  const selectLocale = useCallback(
    (localeId: string) => {
      const { pathname, asPath, query } = router;
      router.push({ pathname, query }, asPath, { locale: localeId.toString() }).then(() => {});
    },
    [router],
  );

  return (
    <Select
      label={<FormattedMessage defaultMessage={'Select Language'} />}
      defaultSelectedKey={defaultKey}
      selectedKey={router.locale}
      onSelectionChange={(locale) => {
        selectLocale(locale as string);
      }}
    >
      {countryLocales.map((country) => (
        // <Item key={locale.id}>{locale.name}</Item>
        <Section
          title={
            <CountrySection>
              <FlagIcon code={country.id as FlagIconCode} size={18} />
              {country.id}
            </CountrySection>
          }
          key={country.id}
          items={country.options}
        >
          {country.options.map((locale) => (
            <Item key={locale.id}>{locale.name}</Item>
          ))}
        </Section>
      ))}
    </Select>
  );

  // return <Select value={locale} options={locales} onChange={props.onChange ?? selectLocale} />;
}

export default LocaleSelect;
