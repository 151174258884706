/** @format */

import Button from '@atoms/Button';
import { cssVars } from '@atoms/GlobalStyles';
import Scrollbar from '@atoms/Scrollbar';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { AriaDialogProps, useDialog } from 'react-aria';
import { FormattedMessage } from 'react-intl';

interface DialogProps extends AriaDialogProps {
  children: React.ReactNode;
  title: string | EmotionJSX.Element;
  confirmDialog?: boolean;
  confirmLabel?: string | EmotionJSX.Element;
  onClose: () => void;
  onConfirm?(): void;
  confirmDisabled?: boolean;
  wide?: boolean;
  disableYScroll?: boolean;
  onDelete?(): void;
  onUndelete?(): void;
}
interface IHeaderProps {
  primary?: boolean;
  warn?: boolean;
  danger?: boolean;
}
const getStyles = (props: IHeaderProps) => {
  const color = props.danger || props.warn || props.primary ? cssVars.white : cssVars.grey;
  const background = props.primary ? cssVars.blue : props.warn ? cssVars.yellow : props.danger ? cssVars.red : 'transparent';
  return { color, background };
};

const StyledDialog = styled.div<{ wide: boolean }>`
  width: ${({ wide }) => (wide ? '95vw' : '50vw')};
  box-sizing: border-box;
  outline: none;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  z-index: 9999999;
  //padding: 5%;
`;

const DialogHeader = styled.div<IHeaderProps>`
  width: 100%;
  border-bottom: 1px solid ${cssVars.lightGrey};
  color: ${(props) => getStyles(props).color};
  background: ${(props) => getStyles(props).background};
  padding: 8px 0 8px 16px;
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  align-items: center;
  position: relative;
  height: 40px;
  user-select: none;
  border-radius: 3px;
  min-height: 16px;
  //box-sizing: content-box;
  svg {
    cursor: pointer;
    color: ${(props) => getStyles(props).color};
    position: absolute;
    right: 16px;
    transition: transform 0.2s ease;
    :hover {
      color: ${(props) => (props.danger ? cssVars.silverGrey : cssVars.red)};
      transform: scale(1.1);
    }
    :active {
      transform: scale(0.9);
    }
  }
`;

const DialogBody = styled.div`
  flex-grow: 1;
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
  gap: 16px;
  padding: 24px 5%;
  height: calc(100% - 36px);
`;

const DialogButtons = styled.div`
  padding: 16px 5%;
  display: flex;
  width: 100%;
  gap: 32px;
  justify-content: flex-end;
  box-sizing: border-box;
`;

function Dialog(props: DialogProps & IHeaderProps) {
  const ref = React.useRef(null);
  const { dialogProps, titleProps } = useDialog(
    {
      ...props,
      role: 'dialog',
    },
    ref,
  );

  return (
    <StyledDialog {...dialogProps} ref={ref} wide={props.wide}>
      <DialogHeader primary={props.primary} danger={props.danger} warn={props.warn}>
        {props.title}
        <FontAwesomeIcon onClick={props.onClose} size="lg" icon={['fas', 'multiply']} />
      </DialogHeader>
      <Scrollbar disableXScroll disableYScroll={props.disableYScroll}>
        <DialogBody>
          {props.children}

          {props.confirmDialog && (
            <DialogButtons>
              <Button onClick={props.onClose}>
                <FormattedMessage defaultMessage="Cancel" description="Cancel button text" />
              </Button>
              <Button primary={!props.danger} error={props.danger} disabled={props.confirmDisabled} onClick={props.onConfirm}>
                {props.confirmLabel ?? <FormattedMessage defaultMessage="Okay" description="Okay button text" />}
              </Button>
              {props.onDelete && (
                <Button error disabled={!props.onDelete} onClick={props.onDelete}>
                  <FormattedMessage defaultMessage="Delete" description="Delete Link Label" />
                </Button>
              )}
              {props.onUndelete && (
                <Button error disabled={!props.onUndelete} onClick={props.onDelete}>
                  <FormattedMessage defaultMessage="Undelete" description="Undelete audit event type label" />
                </Button>
              )}
            </DialogButtons>
          )}
        </DialogBody>
      </Scrollbar>
    </StyledDialog>
  );
}

export default Dialog;
