/** @format */

import styled from '@emotion/styled';
import React, { CSSProperties } from 'react';

interface FlexBoxProps {
  children: any;
  flexDirection?: CSSProperties['flexDirection'];
  justifyContent?: CSSProperties['justifyContent'];
  alignItems?: CSSProperties['alignItems'];
  flex?: CSSProperties['flex'];
  gap?: CSSProperties['gap'];
  width?: CSSProperties['width'];
  wrap?: CSSProperties['flexWrap'];
}

const StyledFlexBox = styled.div<FlexBoxProps>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection ?? 'row'};
  flex: ${(props) => props.flex};
  gap: ${(props) => props.gap};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  width: ${(props) => props.width};
  wrap: ${(props) => props.wrap};
`;

function FlexBox(props: FlexBoxProps) {
  return <StyledFlexBox {...props}>{props.children}</StyledFlexBox>;
}

export default FlexBox;
