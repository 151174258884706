/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AutocompleteModal from '@molecules/AutocompleteModal/AutocompleteModal';
import ElementMerge from '@quarks/ElementMerge';
import React, { ReactNode, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import Input, { InputProps } from '../Input';
import { PrefixedInput } from '../PrefixedInput';
import { StyledAutocomplete } from './styled';

export type UnsortedOption<Type> = { value: string; id: string | number; data?: Type; render: React.FC };

/**
 * @param options {Array<UnsortedOption>} An array of the options to be displayed by the autocomplete
 * @param {string} value the search query
 * @param selected {string} The currently selected option
 * @param  onSelected  function to handle selection of an option
 * @param manual {boolean} (optional) whether the autocomplete logic is handled manually or internally by the component. E.g. set to true if autocomplete is handled by ElasticSearch
 * @param decorator {ReactNode} (optional) an icon for the input (e.g. a magnifying glass)
 * @param disabled {boolean} (optional) whether the input is disabled
 * @param onEmptyResultsConfig {{ message: EmotionJSX.Element; onClick: () => void }} (optional) a message and click behaviour to use when no results are returned
 * @param tabular {boolean} (optional) if this is selected then the results will be displayed in a modal instead of the standard dropdown. Use when there is more than one piece of information displayed for each result
 * */
export interface AutocompleteProps<Type> extends InputProps {
  options: Array<UnsortedOption<Type>>;
  selected: AutocompleteOption<Type>;
  manual?: boolean;
  decorator?: ReactNode;
  disabled?: boolean;
  onEmptyResultsConfig?: { message: EmotionJSX.Element; onClick: () => void };
  onSelected(option: AutocompleteOption<Type>): void;
  label: string | EmotionJSX.Element;
}

export type AutocompleteOption<Type> = { value: string; id: string | number; ld?: number; data?: Type; render: React.FC; score?: number };

const StyledClearButton = styled.span`
  position: absolute;
  top: 1px;
  bottom: 1px;
  right: 1px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${cssVars.white};
  cursor: pointer;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: ${cssVars.black};
`;

const AutocompleteWithModal = function <Type>(props: AutocompleteProps<Type>) {
  const [active, setActive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const inputRef = useRef(null);
  const intl = useIntl();
  const autocomplete = useRef<HTMLDivElement>(null);

  const onLoseFocus = useCallback(() => {
    if (showModal) return;
    // ensure that the input displays the currently selected option
    props.onChange(props.selected?.value ?? '', true);
    setActive(false);
  }, [props.onChange, showModal]);

  const deleteValue = (e) => {
    e.stopPropagation();
    e.preventDefault();
    props.onChange('');
    props.onSelected({ id: null, value: '', render: () => <span></span> });
  };

  // useEffect(() => {
  //   props.onChange(props.selected?.value ?? '');
  // }, [props.selected?.value]);

  const openModal = () => {
    setShowModal(true);
    // inputRef.current.focus();
  };

  // const closeModal = () => {
  //   setShowModal(false);
  // };

  useLayoutEffect(() => {
    onLoseFocus();
  }, [onLoseFocus, showModal]);

  const selectOption = (option: AutocompleteOption<Type>) => {
    props.onSelected(option);
    props.onChange(option.value);
  };

  return (
    <>
      <StyledAutocomplete ref={autocomplete}>
        <ElementMerge>
          <PrefixedInput icon={<FontAwesomeIcon icon={['far', 'search']} />}>
            <Input
              ref={inputRef}
              value={props.selected?.value}
              onChange={(v) => {
                // if (v !== props.value) {
                //   props.onChange(v);
                //   setActive(true);
                // }
              }}
              disabled={props.disabled}
              onFocus={() => {
                setActive(true);
              }}
              onClick={openModal}
              placeholder={intl.formatMessage({ defaultMessage: 'Start typing to search...' })}
              // onClick={props.tabular ? () => openModal() : null}
            />
            {props.selected && (
              <StyledClearButton onClick={deleteValue}>
                <FontAwesomeIcon icon={['fas', 'multiply']} />
              </StyledClearButton>
            )}
          </PrefixedInput>
        </ElementMerge>
      </StyledAutocomplete>
      <AutocompleteModal
        state={{
          isOpen: showModal,
          close: () => setShowModal(false),
          open: () => setShowModal(true),
          setOpen: (bool) => setShowModal(bool),
          toggle: () => setShowModal((b) => !b),
        }}
        search={props.value}
        setSearch={props.onChange}
        options={props.options}
        onSelected={selectOption}
      />
    </>
  );
};

export default AutocompleteWithModal;
