/** @format */

import styled from '@emotion/styled';

const StyledEditModalRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 16px;
  @media (max-width: 576px) {
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
  }
`;

export default StyledEditModalRow;
