/** @format */

import { DocumentClient } from '@common/model/apiClient';
import React from 'react';

function DocumentAutocompleteOption(props: { document: DocumentClient }) {
  return (
    <div>
      ({props.document.id}) {props.document.title}
    </div>
  );
}

export default DocumentAutocompleteOption;
