/** @format */
import { cssVars } from '@atoms/GlobalStyles';
import styled from '@emotion/styled';
import type { Node } from '@react-types/shared';
import React from 'react';
import { useOption } from 'react-aria';
import type { ListState } from 'react-stately';

interface OptionContextValue {
  labelProps: React.HTMLAttributes<HTMLElement>;
  descriptionProps: React.HTMLAttributes<HTMLElement>;
}

interface OptionProps {
  item: Node<unknown>;
  state: ListState<unknown>;
}
interface ListItemProps {
  isFocused?: boolean;
  isSelected?: boolean;
}

const OptionContext = React.createContext<OptionContextValue>({
  labelProps: {},
  descriptionProps: {},
});

const ListItem = styled.li<ListItemProps>`
  font-family: 'Proxima Nova', Roboto, sans-serif;

  background: ${(props) => (props.isFocused ? cssVars.blue : '')};
  color: ${(props) => (props.isFocused ? 'white' : props.isSelected ? cssVars.blue : cssVars.black)};
  font-size: 14px;
  font-weight: ${(props) => (props.isSelected ? '600' : 'normal')};
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: default;
  outline: none;
`;

const ItemContent = styled.div`
  display: flex;
  align-items: center;
`;

export function Option({ item, state }: OptionProps) {
  let ref = React.useRef<HTMLLIElement>(null);
  let { optionProps, labelProps, descriptionProps, isSelected, isFocused } = useOption(
    {
      key: item.key,
    },
    state,
    ref,
  );

  return (
    <ListItem {...optionProps} ref={ref} isFocused={isFocused} isSelected={isSelected}>
      <ItemContent>
        <OptionContext.Provider value={{ labelProps, descriptionProps }}>{item.rendered}</OptionContext.Provider>
      </ItemContent>
      {/*{isSelected && <CheckIcon aria-hidden="true" style={{ width: 18, height: 18 }} />}*/}
    </ListItem>
  );
}

export default Option;
