/** @format */

import Autocomplete from '@atoms/AutocompleteWithModal';
import AutocompleteWithModal, { AutocompleteOption } from '@atoms/AutocompleteWithModal';
import AccountAutocompleteOption from '@atoms/AutocompleteOptions/AccountAutocompleteOption';
import DocumentAutocompleteOption from '@atoms/AutocompleteOptions/DocumentAutocompleteOption';
import Button from '@atoms/Button';
import Dialog from '@atoms/Dialog';
import { apiClient } from '@common/application/enums/apiClient';
import useAutocomplete from '@common/application/hooks/useAutocomplete/useAutocomplete';
import { AccountClient, DocumentClient } from '@common/model/apiClient';
import styled from '@emotion/styled';
import EditModalRow from '@quarks/EditModalRow';
import FlexLabel from '@quarks/FlexLabel';
import InputGroup from '@quarks/InputGroup';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const StyledLoadModal = styled.div``;

interface ILoadModal {
  closeModal(): void;
}

function LoadModal(props: ILoadModal) {
  const [docInput, setDocInput] = useState('');
  const [accountInput, setAccountInput] = useState('');
  const [selectedDocument, setSelectedDocument] = useState<AutocompleteOption<DocumentClient>>(null);
  const [selectedAccount, setSelectedAccount] = useState(null);

  const intl = useIntl();
  const optionRenderer = (data: DocumentClient) => <DocumentAutocompleteOption document={data} key={data.title} />;
  const documentOptions = useAutocomplete(apiClient.Document, DocumentClient, docInput, optionRenderer);
  const accountOptionRenderer = (data: AccountClient) => <AccountAutocompleteOption account={data} key={'account' + data.id} />;
  const accountOptions = useAutocomplete(apiClient.Account, AccountClient, accountInput, accountOptionRenderer);
  const router = useRouter();

  const loadDoc = () => {
    if (!selectedDocument) return;
    // @ts-ignore
    const url = `/account/${selectedDocument.data.accountId}/document/${selectedDocument.id}`;
    router.push(url);
    props.closeModal();
  };

  const loadAccount = () => {
    if (!selectedAccount) return;
    const url = `/account/${selectedAccount.data.id}`;
    router.push(url);
    props.closeModal();
  };

  return (
    <Dialog title={intl.formatMessage({ defaultMessage: 'Load', description: 'Load modal title' })} onClose={props.closeModal}>
      <h2>
        <FormattedMessage defaultMessage={'Documents'} description={'Help documents header'} />
      </h2>
      <EditModalRow>
        <FlexLabel>
          <FormattedMessage defaultMessage="Select document to load" description="Label for document search input" />
          <InputGroup>
            <Autocomplete
              label={<FormattedMessage defaultMessage="Select document to load" description="Label for document search input" />}
              options={documentOptions}
              selected={selectedDocument}
              onSelected={(option) => setSelectedDocument(option)}
              onChange={(v) => setDocInput(v)}
              value={docInput}
            />
            <Button onClick={() => loadDoc()} disabled={!selectedDocument}>
              <FormattedMessage defaultMessage="Load" description="Load modal title" />
            </Button>
          </InputGroup>
        </FlexLabel>
      </EditModalRow>
      <h2>
        <FormattedMessage defaultMessage={'Accounts'} description={'Accounts admin page title'} />
      </h2>
      <EditModalRow>
        <FlexLabel>
          <FormattedMessage defaultMessage={'Search Accounts'} description={'Home page title'} />
          {/*    <FormattedMessage defaultMessage="Select document to load" description="Label for document search input" />*/}
          <InputGroup>
            <Autocomplete
              label={<FormattedMessage defaultMessage={'Search Accounts'} description={'Home page title'} />}
              options={accountOptions}
              selected={selectedAccount}
              onSelected={(option) => setSelectedAccount(option)}
              onChange={(v) => setAccountInput(v)}
              value={accountInput}
            />
            <Button onClick={() => loadAccount()} disabled={!selectedAccount}>
              <FormattedMessage defaultMessage="Load" description="Load modal title" />
            </Button>
          </InputGroup>
        </FlexLabel>
      </EditModalRow>
    </Dialog>
  );
}

export default LoadModal;
