/** @format */

import { AutocompleteOption } from '@atoms/AutocompleteWithModal';
import { apiClient } from '@common/application/enums/apiClient';
import useGetCountryById from '@common/application/hooks/useGetCountryById/useGetCountryById';
import { useRouter } from 'next/router';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useThrottledCallback } from 'use-debounce';

export interface IAutocompleteConfig {
  throttle?: number;
  accountId?: number;
}

/**
 * @param  clientKey   A lowercase string identifier for the client that will be passed to the query key for useQuery
 * @param  client      An API client, which should have a static autocomplete method
 * @param  inputValue  The search value that is passed to the query, returned from a useState
 * @param  render      Function to convert the data returned from the query into a component
 * @param  config      Optional config object which accepts zero or more of: throttle - time in ms to throttle API calls, accountId -
 * */
function useAutocomplete(clientKey: apiClient, client, inputValue: string, render: (data: typeof client) => ReactElement, config: IAutocompleteConfig = {}) {
  const [searchTerm, setSearchTerm] = useState(inputValue);
  const { locale } = useRouter();
  const { data: localeCountry } = useGetCountryById(locale.split('-').pop());
  useEffect(() => {
    if (clientKey === 'countries' && !searchTerm.length) {
      setSearchTerm(localeCountry?.payload?.name ?? '');
    }
  }, [searchTerm, localeCountry, client.name]);

  const query = useQuery([`${clientKey}_not_deleted`, searchTerm], () => client?.autocomplete?.(searchTerm, {}, config.accountId), {
    refetchOnWindowFocus: false,
    refetchInterval: false,
    keepPreviousData: true,
    retry: 1,
  });

  const autocompleteOptions: AutocompleteOption<any>[] = useMemo(() => {
    if (!(query?.data?.payload instanceof Array)) return [];
    return query?.data?.payload?.map((option) => ({
      //This will no doubt cause problems down the road, but honestly I've wasted too much time on it already
      // As countries doesn't return an 'id' key, we want to use it's alpha2 instead - same thing with realm
      id: option._source?.id ?? option._source?.alpha2 ?? option.id,
      value: option._source?.name ?? option.realm,
      render: () => render(option._source ?? option),
      data: option._source,
      score: option._score,
    }));
  }, [query?.data?.payload]);

  const _setSearch = useThrottledCallback(setSearchTerm, config.throttle, { leading: true, trailing: true });

  useEffect(() => {
    _setSearch(inputValue);
  }, [_setSearch, inputValue]);

  return autocompleteOptions;
}

export default useAutocomplete;
