/** @format */

import styled from '@emotion/styled';

const TabularAutocompleteOption = styled.div`
  display: grid;
  grid-template-columns: auto minmax(10px, 1fr) auto minmax(10px, 1fr);
  gap: 12px 8px;
  font-size: 1rem;
  width: 100%;
  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    :nth-of-type(odd) {
      font-weight: bold;
    }
  }
  @media (max-width: 768px) {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    //width: 40%;
    //flex-wrap: wrap;
  }
`;

export default TabularAutocompleteOption;
