/** @format */

import { Account } from '@common/model/apiClient/Account';
import RoleRepresentation from '@keycloak/keycloak-admin-client/lib/defs/roleRepresentation';
import UserRepresentation from '@keycloak/keycloak-admin-client/lib/defs/userRepresentation';
import axios, { AxiosResponse } from 'axios';
import { DocumentClient } from '@common/model/apiClient';

export class User {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  credentials: Object[];
  roles: RoleRepresentation[];
  availableRoles: RoleRepresentation[];
  representation?: UserRepresentation;
  favouriteAccounts: Array<Account>;

  constructor(representation: UserRepresentation) {
    this.representation = representation;
  }

  static list(search: string, page: number): Promise<{ payload: Array<{ _source: User; sort }>; last_page: number; total: { value: number } }> {
    // Ensure this code is not ran by SSR!
    if (typeof window !== 'undefined') {
      return axios
        .get(`${window.location.protocol}//${window.location.host}/api/v1/users`, {
          params: {
            search,
            page,
          },
        })
        .then((res) => ({ ...res.data }));
    }
    throw new Error();
  }

  static get(id: string): Promise<{ message: string; paginated: boolean; payload: User }> {
    return axios.get(`${window.location.protocol}//${window.location.host}/api/v1/users/${id}`).then((res) => ({ ...res.data }));
  }

  static getRoles(id: string): Promise<{ message: string; paginated: boolean; payload: User }> {
    return axios.get(`${window.location.protocol}//${window.location.host}/api/v1/users/${id}/roles`).then((res) => ({ ...res.data }));
  }

  static removeRole(id: string, role: RoleRepresentation): Promise<Record<string, unknown>> {
    return axios
      .delete(`${window.location.protocol}//${window.location.host}/api/v1/users/${id}/roles`, {
        data: { role: role },
      })
      .then((res) => ({ ...res.data }));
  }

  static addRole(id: string, newRole): Promise<Record<string, unknown>> {
    return axios
      .post(`${window.location.protocol}//${window.location.host}/api/v1/users/${id}/roles`, {
        role: newRole,
      })
      .then((res) => ({ ...res.data }));
  }

  static delete(id: string): Promise<Record<string, unknown>> {
    return axios.delete(`${window.location.protocol}//${window.location.host}/api/v1/users/${id}`).then((res) => ({ ...res.data }));
  }

  static undelete(id: string): Promise<Record<string, unknown>> {
    return axios.post(`${window.location.protocol}//${window.location.host}/api/v1/users/${id}/undelete`).then((res) => ({ ...res.data }));
  }

  static update(representation: UserRepresentation, id: string): Promise<Record<string, unknown>> {
    return axios.patch(`${window.location.protocol}//${window.location.host}/api/v1/users/${id}`, { payload: representation }).then((res) => ({ ...res.data }));
    // .catch((err) => console.log(err.response));
  }

  static updateAttributes(
    attributes: Record<string, string>,
    id: string,
  ): Promise<Record<string, { attributes: Record<string, Record<number, string>>; email: string; givenName: string; id: string; familyName: string; username: string }>> {
    return axios
      .patch(`${window.location.protocol}//${window.location.host}/api/v1/users/attributes/${id}`, { payload: { attributes: attributes } })
      .then((res) => ({ ...res.data }));
    // .catch((err) => console.log(err.response));
  }

  static deleteAttributes(attributes: Array<string>, id: string): Promise<Record<string, unknown>> {
    return axios
      .delete(`${window.location.protocol}//${window.location.host}/api/v1/users/attributes/${id}`, {
        data: { attributes: attributes },
      })
      .then((res) => ({ ...res.data }));
    // .catch((err) => console.log(err.response));
  }

  static create(body: { payload: { user: UserRepresentation } }): Promise<Record<string, unknown>> {
    return axios.post(`${window.location.protocol}//${window.location.host}/api/v1/users`, { payload: body.payload }).then((res) => ({ ...res.data }));
    // .catch((err) => console.log(err.response));
  }

  static resetPassword(id: string): Promise<Record<string, unknown>> {
    return axios.post(`${window.location.protocol}//${window.location.host}/api/v1/users/${id}/password-resets`).then((res) => ({ ...res.data }));
  }

  static getStats(
    id: string,
    timeSpan: { start: Date; end: Date },
  ): Promise<{
    payload: {
      savingsTotal: number;
      docCount: number;
    };
  }> {
    return axios
      .get(`${window.location.protocol}//${window.location.host}/api/v1/users/stats/${id}`, { params: { start: timeSpan.start, end: timeSpan.end } })
      .then((res) => ({ ...res.data }));
  }

  add(): Promise<Record<string, unknown>> {
    return axios
      .post(`${window.location.protocol}//${window.location.host}/api/v1/users`, {
        payload: {
          representation: this.representation,
        },
      })
      .then((res) => ({ ...res.data }));
  }

  static addFavouriteAccount(userId: string, accountId: number) {
    return axios.post(`${window.location.protocol}//${window.location.host}/api/v1/users/${userId}/favourite-accounts`, { payload: { accountId } });
  }

  static deleteFavouriteAccount(userId: string, accountId: number) {
    return axios.delete(`${window.location.protocol}//${window.location.host}/api/v1/users/${userId}/favourite-accounts`, { params: { accountId } });
  }

  static getFavouriteAccounts(userId: string) {
    return axios.get(`${window.location.protocol}//${window.location.host}/api/v1/users/${userId}/favourite-accounts`);
  }

  static getRecentDocuments(userId: string): Promise<AxiosResponse<{ payload: Array<DocumentClient> }>> {
    return axios.get(`${window.location.protocol}//${window.location.host}/api/v1/users/${userId}/recent-documents`);
  }

  static getHistory(userId: string) {
    return axios.get(`${window.location.protocol}//${window.location.host}/api/v1/users/${userId}/history`);
  }
}
