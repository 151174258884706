/** @format */

export enum apiClient {
  Account = 'accounts',
  About = 'abouts',
  Attachment = 'attachments',
  AttachmentType = 'attachmentTypes',
  Audit = 'audits',
  Branch = 'branches',
  Brand = 'brands',
  Company = 'companies',
  CompanyBrand = 'companyBrands',
  Contact = 'contacts',
  Country = 'countries',
  Currency = 'currencies',
  DataExport = 'dataExports',
  Document = 'documents',
  DocumentSchedule = 'documentSchedules',
  DocumentScheduleType = 'documentScheduleTypes',
  FieldConfiguration = 'fieldConfigurations',
  Help = 'help',
  HelpType = 'helpTypes',
  IndustryType = 'industryTypes',
  IndustryTypeLocale = 'industryTypeLocales',
  Locale = 'locales',
  Realm = 'realms',
  Saving = 'savings',
  SavingLine = 'savingLines',
  Site = 'sites',
  User = 'users',
}
