/** @format */

import styled from '@emotion/styled';

const StyledInputGroup = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  > :first-of-type {
    margin-right: 16px;
  }
`;

export default StyledInputGroup;
