/** @format */

import { DocumentScheduleTypeId } from '@common/application/enums/DocumentScheduleType';
import { ShortcutAction } from '@common/application/hooks/useKeyboardShortcuts';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const useStrings = () => {
  const intl = useIntl();

  const tableStrings = useMemo(
    () => ({
      // This and site are duplicated as there are two different functions (column sorting and entity table generation) looking for the same item with different keys
      accountName: <FormattedMessage defaultMessage="Account" description="Table Header Account" />,
      account: <FormattedMessage defaultMessage="Account" description="Table Header Account" />,
      accountNumber: <FormattedMessage defaultMessage={'Account Number'} description={'Home page table account number header'} />,
      actions: <FormattedMessage defaultMessage={'Actions'} description={'Home page table actions header'} />,
      attachmentType: <FormattedMessage defaultMessage={'Attachment Type'} description={'Type of attachment'} />,
      author: <FormattedMessage defaultMessage={'Author'} description={'Author label'} />,
      brand: <FormattedMessage defaultMessage="Brand" description="Brand Input Label" />,
      company: <FormattedMessage defaultMessage="Company" description="Company Input Label" />,
      companyName: <FormattedMessage defaultMessage="Company" description="Table header Company" />,
      createdAt: <FormattedMessage defaultMessage={'Created At'} description={'Table header Created At'} />,
      approvedAt: <FormattedMessage defaultMessage={'Approved At'} description={'Table header Approved At'} />,
      createdBy: <FormattedMessage defaultMessage={'Created By'} description={'Created By label'} />,
      deletedAt: <FormattedMessage defaultMessage="Deleted At" description="Table Header Deleted At" />,
      description: <FormattedMessage defaultMessage={'Description'} description={'Home page table description header'} />,
      email: <FormattedMessage defaultMessage="Email" description="Table Header Email" />,
      download: <FormattedMessage defaultMessage="Download" description="Download link" />,
      embed: <FormattedMessage defaultMessage="Embed" description="Table header Embed" />,
      erpId: <FormattedMessage defaultMessage="ERP ID" description="Table Header ERP ID" />,
      extraAccountNumbers: <FormattedMessage defaultMessage="Extra Account Numbers" description="Extra Account Numbers Input Label" />,
      field: <FormattedMessage defaultMessage="Field" description="Field Table Header" />,
      givenName: <FormattedMessage defaultMessage="First Name" description="Table Header First Name" />,
      helpTypeId: <FormattedMessage defaultMessage="Help Type" description="Table header Help Type" />,
      hidden: <FormattedMessage defaultMessage="Hidden?" description="Is Field Hidden Table Header" />,
      id: <FormattedMessage defaultMessage="ID" description="Table Header ID" />,
      industry: <FormattedMessage defaultMessage="Industry Category" description="Type of industry that an account falls in to (e.g agriculture, manufacturing etc.)" />,
      familyName: <FormattedMessage defaultMessage="Last Name" description="Table Header Last Name" />,
      localeId: <FormattedMessage defaultMessage="Locale" description="Table header Locale" />,
      logo: <FormattedMessage defaultMessage={'Logo'} description={'Home page table logo header'} />,
      mandatory: <FormattedMessage defaultMessage="Mandatory?" description="Is Field Mandatory Table Header" />,
      name: <FormattedMessage defaultMessage={'Name'} description={'Home page table name header'} />,
      nationalAccountNumber: <FormattedMessage defaultMessage={'National Account Number'} description={'Home page table national account number header'} />,
      phone: <FormattedMessage defaultMessage="Phone" description="Table Header Phone" />,
      realm: <FormattedMessage defaultMessage="Realm" description="Realm input label" />,
      role: <FormattedMessage defaultMessage="Role" description="Table Header Role" />,
      siteAccountNumber: <FormattedMessage defaultMessage="Site Number" description="Table Header Site Number" />,
      siteName: <FormattedMessage defaultMessage="Site" description="Table Header Site" />,
      site: <FormattedMessage defaultMessage="Site" description="Table Header Site" />,
      summary: <FormattedMessage defaultMessage={'Document Summary'} description={'Summary of the status and number of documents associated with the given account'} />,
      title: <FormattedMessage defaultMessage="Title" description="Table header Title" />,
      updatedAt: <FormattedMessage defaultMessage={'Updated At'} description="Table Header Updated At" />,
      url: <FormattedMessage defaultMessage="URL" description="Table header URL" />,
      username: <FormattedMessage defaultMessage="Username" description="Table Header Username" />,
      extraSiteNumbers: <FormattedMessage defaultMessage="Extra Site Numbers" description="Table Header Extra Site Numbers" />,
      nationalSiteNumber: <FormattedMessage defaultMessage="National Site Number" description="Table Header National Site Number" />,
      siteNumber: <FormattedMessage defaultMessage="Site Number" description="Table Header Site Number" />,
      proposedBy: <FormattedMessage defaultMessage="Proposed By" description="Table Header indicating the user that suggested a new contact" />,
      savingsTotal: <FormattedMessage defaultMessage={'Total'} description="Document total label" />,
    }),
    [],
  );

  const actionStrings = useMemo(
    () => ({
      open: <FormattedMessage defaultMessage="Open" description="Open link next to account on home page" />,
      delete: <FormattedMessage defaultMessage="Delete" description="Delete Link Label" />,
      edit: <FormattedMessage defaultMessage="Edit" description="Edit Link Label" />,
      undelete: <FormattedMessage defaultMessage="Undelete" description="Undelete Link Label" />,
      downloadTemplate: <FormattedMessage defaultMessage="Download Template" description="Download Template Link Label" />,
      add: <FormattedMessage defaultMessage="Add" description="Add Button Label" />,
      upload: <FormattedMessage defaultMessage="Upload" description="Upload Button Label" />,
      approve: <FormattedMessage defaultMessage="Approve" description="Approve Link Label" />,
      createNewUser: <FormattedMessage defaultMessage="Create New User" description="Create new user label" />,
      addField: <FormattedMessage defaultMessage="Add Field" description="Add Field Form Header" />,
      cancel: <FormattedMessage defaultMessage="Cancel" description="Cancel button text" />,
      continue: <FormattedMessage defaultMessage="Continue" description="Label for button to continue to next step of process" />,
      confirm: <FormattedMessage defaultMessage="Confirm" description="Confirm choices and continue" />,
      makeDefault: <FormattedMessage defaultMessage="Make Default" description="Make the selected option the default choice from now on" />,
    }),
    [],
  );

  const headerStrings = useMemo(
    () => ({
      accounts: <FormattedMessage defaultMessage="Accounts" description="Accounts admin page title" />,
      addAccount: <FormattedMessage defaultMessage="Add Account" description="Add account Header" />,
      addBranch: <FormattedMessage defaultMessage="Add Branch" description="Add Branch Header" />,
      addTag: <FormattedMessage defaultMessage="Add New Category" description="Add Category Header" />,
      addCompanyBrand: <FormattedMessage defaultMessage="Add Company Brand" description="Add Company Brand Header" />,
      addContact: <FormattedMessage defaultMessage="Add Contact" description="Add Contact Header" />,
      addHelp: <FormattedMessage defaultMessage="Add Help" description="Add help header" />,
      addIndustry: <FormattedMessage defaultMessage="Add Industry Types" description="Add industry type header" />,
      addMissingCompanies: <FormattedMessage defaultMessage="Add Missing Companies" description="Add Missing Companies Header" />,
      addMultipleAccounts: <FormattedMessage defaultMessage="Add Multiple Accounts" description="Add Multiple Accounts Header" />,
      addMultipleBranches: <FormattedMessage defaultMessage="Add Multiple Branches" description="Add Multiple Branches Header" />,
      addMultipleContacts: <FormattedMessage defaultMessage="Add Multiple Contacts" description="Add Multiple Contacts Header" />,
      addMultipleSites: <FormattedMessage defaultMessage="Add Multiple Sites" description="Add Multiple Sites Header" />,
      addSite: <FormattedMessage defaultMessage="Add Site" description="Add Site Header" />,
      admin: <FormattedMessage description={'Administration side menu header'} defaultMessage={'Administration'} />,
      approveContacts: <FormattedMessage defaultMessage="Approve Contacts" description="Header for table of contacts awaiting approval" />,
      attachments: <FormattedMessage defaultMessage={'Attachments'} description={'Attachments modal title'} />,
      branches: <FormattedMessage defaultMessage="Branches" description="Title" />,
      companyBrands: <FormattedMessage defaultMessage="Company Brands" description="Title" />,
      contacts: <FormattedMessage defaultMessage="Contacts" description="Title" />,
      fieldConfigurations: <FormattedMessage defaultMessage="Field Configurations" description="Mandatory fields table title" />,
      help: <FormattedMessage defaultMessage="Help" description="Help title" />,
      industries: <FormattedMessage defaultMessage="Industry Types" description="Industry Types title" />,
      manageAccounts: <FormattedMessage defaultMessage="Manage Accounts" description="Manage Accounts Header" />,
      manageBranches: <FormattedMessage defaultMessage="Manage Branches" description="Manage Branches Header" />,
      manageCompanyBrands: <FormattedMessage defaultMessage="Manage Company Brands" description="Manage Company Brands Header" />,
      manageContacts: <FormattedMessage defaultMessage="Manage Contacts" description="Manage Contacts Header" />,
      manageHelp: <FormattedMessage defaultMessage="Manage Help" description="Manage Help header" />,
      manageIndustries: <FormattedMessage defaultMessage="Manage Industry Types" description="Manage Industry Types header" />,
      manageSites: <FormattedMessage defaultMessage="Manage Sites" description="Manage Sites Header" />,
      mandatoryFields: <FormattedMessage description={'Administration side menu mandatory fields link'} defaultMessage={'Mandatory Fields'} />,
      sites: <FormattedMessage defaultMessage="Sites" description="Title" />,
      undeleteAccounts: <FormattedMessage defaultMessage="Undelete Accounts" description="Undelete Accounts Header" />,
      undeleteBranches: <FormattedMessage defaultMessage="Undelete Branches" description="Undelete Branches Header" />,
      undeleteCompanyBrands: <FormattedMessage defaultMessage="Undelete Company Brands" description="Undelete Company Brands Header" />,
      undeleteContacts: <FormattedMessage defaultMessage="Undelete Contacts" description="Undelete Contacts Header" />,
      undeleteHelp: <FormattedMessage description="Undelete Help header" defaultMessage="Undelete Help" />,
      undeleteIndustries: <FormattedMessage defaultMessage="Undelete Industry Types" description="Undelete Industry Types header" />,
      undeleteSites: <FormattedMessage defaultMessage="Undelete Sites" description="Undelete Sites Header" />,
      users: <FormattedMessage defaultMessage="Users" description="Users admin page title" />,
      companies: <FormattedMessage defaultMessage="Companies" description="Companies title" />,
      addCompany: <FormattedMessage defaultMessage="Add Company" description="Add company header" />,
      manageCompanies: <FormattedMessage defaultMessage="Manage Companies" description="Manage Companies header" />,
      manageTags: <FormattedMessage defaultMessage="Manage Categories" description="Header for admin section to manage account and document categories" />,
      undeleteCompanies: <FormattedMessage defaultMessage="Undelete Companies" description="Undelete Companies header" />,
      undeleteTags: <FormattedMessage defaultMessage="Undelete Categories" description="Undelete Categories header" />,
      templates: <FormattedMessage defaultMessage={'Template Design'} description={'Design (e.g. color scheme) for document templates'} />,
      tags: (
        <FormattedMessage
          defaultMessage={'Category Tags'}
          description={
            'Tags/Labels that are applied to an entity (e.g. a document or account) to add additional context. For example an account could be tagged as having contractual obligations'
          }
        />
      ),
    }),
    [],
  );

  const formStrings = useMemo(
    () => ({
      account: <FormattedMessage defaultMessage="Account" description="Account Input Label" />,
      accountNumber: <FormattedMessage defaultMessage="Account Number" description="Account Number InputLabel" />,
      author: <FormattedMessage defaultMessage="Author" description="Author label" />,
      addressLines: <FormattedMessage defaultMessage="Address Lines" description="Address lines label" />,
      attributes: <FormattedMessage defaultMessage="Attributes" description="Attributes Input Label" />,
      branch: <FormattedMessage defaultMessage="Branch" description="Branch label" />,
      branchNumber: <FormattedMessage defaultMessage="Branch Number" description="Branch Number Input Label" />,
      brand: <FormattedMessage defaultMessage="Brand" description="Brand Input Label" />,
      branding: <FormattedMessage defaultMessage="Branding" description="Branding label" />,
      company: <FormattedMessage defaultMessage="Company" description="Company Input Label" />,
      companyBrandNumber: <FormattedMessage defaultMessage="Company Brand Number" description="Company Brand Number Input Label" />,
      contact: <FormattedMessage defaultMessage="Contact" description="Contact label" />,
      country: <FormattedMessage defaultMessage="Country" description="Country input label" />,
      description: <FormattedMessage defaultMessage="Description" description="Description Input Label" />,
      document: <FormattedMessage defaultMessage="Document" description="Document input label" />,
      embed: <FormattedMessage defaultMessage="Embed?" description="Embed? input label" />,
      extraAccountNumbers: <FormattedMessage defaultMessage="Extra Account Numbers" description="Extra Account Numbers Input Label" />,
      extraBranchNumbers: <FormattedMessage defaultMessage="Extra Branch Numbers" description="Extra Branch Numbers Input Label" />,
      extraSiteNumbers: <FormattedMessage defaultMessage="Extra Site Numbers" description="Extra Site Numbers Input Label" />,
      field: <FormattedMessage defaultMessage="Field" description="Field Input Label" />,
      helpTypeId: <FormattedMessage defaultMessage="Help Type" description="Help Type input label" />,
      hidden: <FormattedMessage defaultMessage="Hidden" description="Label to toggle between hidden and visible" />,
      industry: <FormattedMessage defaultMessage="Industry Category" description="Type of industry that an account falls in to (e.g agriculture, manufacturing etc.)" />,
      locale: <FormattedMessage defaultMessage="Locale" description="Locale input label" />,
      locality: <FormattedMessage defaultMessage="Locality" description="Locality input label" />,
      logo: <FormattedMessage defaultMessage="Logo" description="Logo Input Label" />,
      mandatory: <FormattedMessage defaultMessage="Mandatory" description="Label to toggle Mandatory status of a field" />,
      name: <FormattedMessage defaultMessage="Name" description="Name Input Label" />,
      nationalAccountNumber: <FormattedMessage defaultMessage="National Account Number" description="National Account Number Input Label" />,
      nationalBranchNumber: <FormattedMessage defaultMessage="National Branch Number" description="National Branch Number Input Label" />,
      nationalSiteNumber: <FormattedMessage defaultMessage="National Site Number" description="National Site Number Input Label" />,
      postcode: <FormattedMessage defaultMessage="Postcode" description="Postcode input label" />,
      realm: <FormattedMessage defaultMessage="Realm" description="Realm input label" />,
      region: <FormattedMessage defaultMessage="Region" description="Region input label" />,
      results: <FormattedMessage defaultMessage="Results" description="Results label" />,
      site: <FormattedMessage defaultMessage="Site" description="Site label" />,
      siteAccountNumber: <FormattedMessage defaultMessage="Site Account Number" description="Site Account Number input label" />,
      siteNumber: <FormattedMessage defaultMessage="Site Number" description="Site Number Input Label" />,
      template: <FormattedMessage defaultMessage="Template" description="Template Input Label" />,
      templateInstructions: <FormattedMessage defaultMessage="Drop a completed template here or click to upload." description="Template Input Description" />,
      title: <FormattedMessage defaultMessage="Title" description="Title input label" />,
      startDate: <FormattedMessage defaultMessage="Start Date" description="Starting Date" />,
      endDate: <FormattedMessage defaultMessage="End Date" description="End Date" />,
      message: <FormattedMessage defaultMessage="Message" description="Text to be displayed" />,
      text: <FormattedMessage defaultMessage="Text" description="Text to be displayed" />,
      entityType: <FormattedMessage defaultMessage="Entity Type" description="The entity type that a label will be attached to (e.g. 'document' or 'account')" />,
      categoryName: <FormattedMessage defaultMessage="Category Name" description="The name of the newly created category for categorising accounts" />,

      // selectPlaceholder: <FormattedMessage defaultMessage="Select An Option…" description="default option placeholder" />,
    }),
    [],
  );

  const tooltipStrings = useMemo(
    () => ({
      accountDescription: <FormattedMessage defaultMessage="A description of the account's purpose" description="Description Input help popup" />,
      accountExtraAccountNumbers: <FormattedMessage defaultMessage="Any other account numbers associated with the new account" description="Extra Account Numbers help popup" />,
      accountLogo: <FormattedMessage defaultMessage="A file containing an image of the account's logo" description="Logo Input help popup" />,
      accountTemplateExtraAccountNumbers: (
        <FormattedMessage
          defaultMessage="Any other account numbers associated with the new account. Separate each account number with a comma, do NOT add any spaces"
          description="Extra Account Numbers help popup"
        />
      ),
      branchErpId: <FormattedMessage defaultMessage="Your ERP reference for this branch (if any)." description="ERP ID input tooltip" />,
      branchAddressLines: (
        <FormattedMessage
          defaultMessage="Address of the branch being added. If there are multiple lines, click the 'Add' button after each line."
          description="Address Lines input tooltip"
        />
      ),
      branchTemplateAddressLines: (
        <FormattedMessage
          defaultMessage="Address of the branch being added. If there are multiple lines, separate each line with a comma (the , symbol), do NOT add any spaces."
          description="Address Lines template field tooltip"
        />
      ),
      siteAddressLines: (
        <FormattedMessage
          defaultMessage="Address of the site being added. If there are multiple lines, click the 'Add' button after each line."
          description="Address Lines input tooltip"
        />
      ),
      siteErpId: <FormattedMessage defaultMessage="Your ERP reference for this site (if any)." description="ERP ID input tooltip" />,
      companyBrandsLogo: <FormattedMessage defaultMessage="A file containing the logo of the new company being added." description="Logo Input help popup" />,
      companyLogo: <FormattedMessage defaultMessage="A file containing the logo of the new company being added." description="Logo Input help popup" />,
      companyRealm: (
        <FormattedMessage
          defaultMessage="The name of the SSO realm for the new company. This will need to have been created before the new company can be added."
          description="Realm Input help popup"
        />
      ),
    }),
    [],
  );

  const feedbackStrings = useMemo(
    () => ({
      success: <FormattedMessage defaultMessage="Success!" description="Notification that an action was completed successfully" />,
      fail: <FormattedMessage defaultMessage="Sorry, an error occurred." description="Notification that there was an error while performing an action" />,
      largeLogoError: <FormattedMessage defaultMessage="Image is too big please upload an image that is 256x256 or smaller!" description="Handle Logo Added Error" />,
      multipleLogoError: <FormattedMessage defaultMessage="Can't add more than one logo!" description="Handle Logo Validate Error" />,
      multipleTemplateError: <FormattedMessage defaultMessage="Can't add more than one template!" description="Handle Template Validate Error" />,
      multipleDocumentError: <FormattedMessage defaultMessage="Can't add more than one document!" description="Can't add multiple error message" />,
      addressLinesNotAdded: (
        <FormattedMessage
          defaultMessage="There is text in the Address Lines input field that has not been added. Please either press the add button to include it in the new site or delete it before continuing."
          description="Warning that there is text in the address lines input field that will not be added to the new site "
        />
      ),
      nameMissing: <FormattedMessage defaultMessage="Name field missing" description="Notification that the 'name' field is empty" />,
      accountMissing: <FormattedMessage defaultMessage="Please select a valid account" description="Notification that an account has not been selected" />,
      cannotHideMandatory: (
        <FormattedMessage
          defaultMessage={'Cannot make mandatory field hidden!'}
          description={'Notification indicating that a user is trying to hide a field that has been marked as mandatory'}
        />
      ),
      cannotMandatoryHidden: (
        <FormattedMessage
          defaultMessage={'Cannot make hidden field mandatory!'}
          description={'Notification indicating that a user is trying to make a field that is hidden mandatory'}
        />
      ),
      savingsLoadError: intl.formatMessage({ defaultMessage: 'Error loading savings', description: 'Error message indicating that savings data failed to load from server' }),
      changesSaved: intl.formatMessage({ defaultMessage: 'Changes were successfully saved!', description: 'Save confirmation message' }),
      documentSaved: intl.formatMessage({ defaultMessage: 'New document saved!', description: 'Save confirmation message' }),
      addBranding: intl.formatMessage({ defaultMessage: 'Please add branding.', description: 'Notification that no branding has been supplied for the document' }),
      addTitle: intl.formatMessage({ defaultMessage: 'Please add a title.', description: 'Notification that no title has been input for the document' }),
      addAuthor: intl.formatMessage({ defaultMessage: 'Please select an author.', description: 'Notification that no author has been selected for the document' }),
      addBranch: intl.formatMessage({ defaultMessage: 'Please select a branch.', description: 'Notification that no branch has been selected for the document' }),
      addSite: intl.formatMessage({ defaultMessage: 'Please select a site.', description: 'Notification that no site has been selected for the document' }),
      addContact: intl.formatMessage({ defaultMessage: 'Please select a contact.', description: 'Notification that no contact has been selected for the document' }),
      addDescription: intl.formatMessage({ defaultMessage: 'Please add a description.', description: 'Notification that no description has been selected for the document' }),
      addResults: intl.formatMessage({ defaultMessage: 'Please add the results.', description: 'Notification that no results have been added for the document' }),
    }),
    [intl],
  );

  const settingsHeaderStrings = useMemo(
    () => ({
      accountTableSettings: (
        <FormattedMessage
          defaultMessage={'Account Table Columns'}
          description={'Header for section that contains settings to alter which columns are visible for the account table'}
        />
      ),
      documentTableSettings: (
        <FormattedMessage
          defaultMessage={'Document Table Columns'}
          description={'Header for section that contains settings to alter which columns are visible for the document table'}
        />
      ),
      attachments: <FormattedMessage defaultMessage={'Attachments'} description={'Attachments modal title'} />,
      adminAccount: headerStrings.manageAccounts,
      adminApproveContact: headerStrings.approveContacts,
      adminBranch: headerStrings.manageBranches,
      adminBrand: headerStrings.manageCompanyBrands,
      adminCompany: headerStrings.manageCompanies,
      adminContact: headerStrings.manageContacts,
      adminDeleteCompany: headerStrings.undeleteCompanies,
      adminDeleteAccount: headerStrings.undeleteAccounts,
      adminDeleteBranch: headerStrings.undeleteBranches,
      adminDeleteBrand: headerStrings.undeleteCompanyBrands,
      adminDeleteContact: headerStrings.undeleteContacts,
      adminDeleteHelp: headerStrings.undeleteHelp,
      adminDeleteIndustry: headerStrings.undeleteIndustries,
      adminDeleteSite: headerStrings.undeleteSites,
      adminHelp: headerStrings.manageHelp,
      adminIndustry: headerStrings.manageIndustries,
      adminSite: headerStrings.manageSites,
      adminUser: headerStrings.users,
    }),
    [
      headerStrings.approveContacts,
      headerStrings.manageAccounts,
      headerStrings.manageBranches,
      headerStrings.manageCompanies,
      headerStrings.manageCompanyBrands,
      headerStrings.manageContacts,
      headerStrings.manageHelp,
      headerStrings.manageIndustries,
      headerStrings.manageSites,
      headerStrings.undeleteAccounts,
      headerStrings.undeleteBranches,
      headerStrings.undeleteCompanies,
      headerStrings.undeleteCompanyBrands,
      headerStrings.undeleteContacts,
      headerStrings.undeleteHelp,
      headerStrings.undeleteIndustries,
      headerStrings.undeleteSites,
      headerStrings.users,
    ],
  );

  const notificationStrings = useMemo(
    () => ({
      notificationsHeader: <FormattedMessage defaultMessage="Notifications" description="Header for notifications section" />,
      noNotifications: <FormattedMessage defaultMessage="No new notifications" description="Message that the user's notifications inbox is empty" />,
      markAllRead: <FormattedMessage defaultMessage="Mark All As Read" description="Button to show all notifications as having been read" />,
      clearAll: <FormattedMessage defaultMessage="Delete All" description="Button to delete all notifications" />,
      contact: {
        approved: (
          <FormattedMessage defaultMessage="The contact you submitted has been approved" description="Notification that the user's proposed contact was approved by an admin" />
        ),
        proposed: (
          <FormattedMessage
            defaultMessage="There is a new contact awaiting approval"
            description="Notification that a user has suggested a new contact that needs to be approved"
          />
        ),
        rejected: (
          <FormattedMessage
            defaultMessage="The contact you submitted has been rejected"
            description="Notification that the user's proposed contact was not approved/rejected by an admin"
          />
        ),
      },
      account: {
        industryProposed: <FormattedMessage defaultMessage={'An industry category is awaiting approval'} />,
      },
      resource: {
        quarantine: (
          <FormattedMessage
            defaultMessage="A file you uploaded has been marked as potentially harmful and quarantined."
            description="Notification that a potential virus has been found in a file the user uploaded."
          />
        ),
        notFound: (
          <FormattedMessage
            defaultMessage="There was an error with a file you uploaded, please try again."
            // description="Notification that a potential virus has been found in a file the user uploaded."
          />
        ),
      },
    }),
    [],
  );

  const shortcutStrings = useMemo(
    () => ({
      [ShortcutAction.SAVE]: intl.formatMessage({ defaultMessage: 'Save', description: 'Toolbar file menu save document text' }),
      [ShortcutAction.LOAD]: intl.formatMessage({ defaultMessage: 'Load', description: 'Toolbar file menu load document text' }),
      [ShortcutAction.SAVEAS]: intl.formatMessage({ defaultMessage: 'Save As', description: 'Toolbar file menu save as document text' }),
      [ShortcutAction.NEWDOC]: intl.formatMessage({ defaultMessage: 'Start New Document', description: 'Account page link to start a new document' }),
      [ShortcutAction.ATTACHMENTS]: intl.formatMessage({ defaultMessage: 'Open Attachments', description: 'Toolbar tools menu open attachments text' }),
      [ShortcutAction.SAVETEMPLATE]: intl.formatMessage({ defaultMessage: 'Save Template', description: 'Toolbar file menu save as template text' }),
      [ShortcutAction.TOGGLEPERCENT]: intl.formatMessage({ defaultMessage: 'Toggle Percentage', description: 'Toggle between showing or hiding the percentages in the document' }),
      [ShortcutAction.DOWNLOAD]: intl.formatMessage({ defaultMessage: 'Download', description: 'Download link' }),
      [ShortcutAction.MAIL]: intl.formatMessage({ defaultMessage: 'Email Document', description: 'Send the document as an attachment in an email' }),
      [ShortcutAction.PRINT]: intl.formatMessage({ defaultMessage: 'Print', description: 'Print the document' }),
      [ShortcutAction.TOGGLEIMAGES]: intl.formatMessage({ defaultMessage: 'Toggle Images', description: 'Toggle between showing or hiding uploaded images/photos' }),
    }),
    [intl],
  );

  const roleStrings = useMemo(
    () => ({
      Administrator: intl.formatMessage({
        defaultMessage: 'Administrator',
        description: 'Administrator Role',
      }),
      User: intl.formatMessage({
        defaultMessage: 'User',
        description: 'User Role',
      }),
      'Super User': intl.formatMessage({
        defaultMessage: 'Super User',
        description: 'Super User Role',
      }),
      'Super Administrator': intl.formatMessage({
        defaultMessage: 'Super Administrator',
        description: 'Super Administrator Role',
      }),
    }),
    [intl],
  );

  type TDocumentScheduleStrings = Record<DocumentScheduleTypeId, string>;

  const documentScheduleStrings: TDocumentScheduleStrings = useMemo(
    () => ({
      [DocumentScheduleTypeId.ACCEPTED]: intl.formatMessage({ defaultMessage: 'Approved', description: 'Signed document has been approved by the customer' }),
      [DocumentScheduleTypeId.CREATED]: intl.formatMessage({ defaultMessage: 'Created', description: 'Created date label label' }),
      [DocumentScheduleTypeId.PROVIDED]: intl.formatMessage({ defaultMessage: 'Provided to Customer', description: 'Document has been sent to the customer' }),
      [DocumentScheduleTypeId.OTHER]: intl.formatMessage({ defaultMessage: 'Other', description: 'Other category title' }),
      [DocumentScheduleTypeId.REJECTED]: intl.formatMessage({ defaultMessage: 'Rejected', description: 'The document has been refused by the customer' }),
    }),
    [intl],
  );

  return {
    tableStrings,
    documentScheduleStrings,
    actionStrings,
    tooltipStrings,
    headerStrings,
    formStrings,
    feedbackStrings,
    settingsHeaderStrings,
    notificationStrings,
    shortcutStrings,
    roleStrings,
  };
};

export default useStrings;
