/** @format */

import PopoverTrigger from '@atoms/PopoverTrigger';
import UserIcon from '@atoms/UserIcon';
import { useSecureSession } from '@heimdall/react';
import { BaseRealmRole, BaseRole } from '@heimdall/types';
import { UserMenu } from '@templates/App/UserMenu';

export function UserButton() {
  const session = useSecureSession<BaseRealmRole, BaseRole>();

  return (
    <PopoverTrigger
      label={'Open User Menu'}
      placement={'bottom'}
      button={<UserIcon>{session.status === 'authenticated' ? session?.data?.user?.given_name?.substring(0, 1) + session?.data?.user?.family_name?.substring(0, 1) : ''}</UserIcon>}
      arrowed
    >
      <UserMenu />
    </PopoverTrigger>
  );
}

export default UserButton;
