/** @format */

import { styles } from '@common/application/styles/global';
import styled from '@emotion/styled';

const StyledTagInput = styled.input`
  margin: 0;
  height: 100%;
  flex-grow: 1;
  border: none;
  padding: 8px;
  background-color: ${({ disabled }) => (disabled ? '#fcfcfc' : styles.backgroundColor)};

  :hover,
  :focus {
    outline: none;
  }
`;

export default StyledTagInput;
