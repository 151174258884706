/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import { keyframes } from '@emotion/react';

import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import { AriaModalOverlayProps, Overlay, useModalOverlay } from 'react-aria';
import { OverlayTriggerState } from 'react-stately';

const blur = keyframes`
from{
  backdrop-filter: blur(1px);
  background: ${cssVars.black}09;

}
100%{
  background: ${cssVars.black}44;
  backdrop-filter: blur(3px);

}
`;

const ModalWrapper = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${blur} 0.2s linear forwards;
`;

const StyledModal = styled.div`
  background: ${cssVars.white};
  border-radius: 3px;
  //width: fit-content;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  //max-height: 90%;
`;

function Modal({ state, children, ...props }: AriaModalOverlayProps & { state: OverlayTriggerState; children: ReactNode }) {
  const ref = React.useRef(null);
  const { modalProps, underlayProps } = useModalOverlay(props, state, ref);

  if (!state.isOpen) return null;
  return (
    <Overlay>
      <ModalWrapper {...underlayProps}>
        <StyledModal {...modalProps} ref={ref}>
          {children}
        </StyledModal>
      </ModalWrapper>
    </Overlay>
  );
}

export default Modal;
