/** @format */

import updateLegacySettings from '@common/application/helpers/updateLegacySettings';
import { TSettings } from '@common/application/hooks/useDefaultSettings';
import useQueryFeedback from '@common/application/hooks/useQueryFeedback/useQueryFeedback';
import { User } from '@common/model/apiClient/User';
import { useSecureSession } from '@heimdall/react';
import { BaseRealmRole, BaseRole } from '@heimdall/types';
import { AxiosError } from 'axios';
import { isEqual } from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export enum SettingsKey {
  TABLES = 'tableSettings',
  KEY = 'keyMap',
  DOCUMENT = 'document',
}

const reducer = (state, newState) => {
  return { ...state, ...newState };
};

function useUserSettings(key: SettingsKey, defaultValues: TSettings) {
  const [settings, setSettings] = useReducer(reducer, defaultValues);
  const [isLoaded, setIsLoaded] = useState(false);
  const [savedSettings, setSavedSettings] = useState(JSON.stringify(defaultValues));
  const { notifySuccess, notifyFail } = useQueryFeedback();
  const session = useSecureSession<BaseRealmRole, BaseRole>();

  const queryClient = useQueryClient();

  const updateSettings = useMutation(
    ['update_user_settings'],
    async (settings: {}) => await User.updateAttributes({ [`trackUpSettings_${key}`]: JSON.stringify(settings) }, session?.data?.user?.id),
    {
      onSuccess: (_) => {
        setSavedSettings(JSON.stringify(settings));

        queryClient.invalidateQueries([`trackUpSettings_${key}`]);
      },
      onError: (e: AxiosError<{ payload: any }>) => {
        notifyFail(e);
        throw new Error(e.message);
      },
    },
  );

  const saveChanges = async (successCallback?: () => void) => {
    if (isEqual(JSON.parse(savedSettings), settings) || !updateSettings.isIdle) {
      return null;
    }
    return await updateSettings.mutateAsync(settings, { onSuccess: () => (successCallback ? successCallback() : notifySuccess()) });
  };

  const undoChanges = () => {
    setSettings(JSON.parse(savedSettings));
  };

  const userSettings = useQuery([`trackUpSettings_${key}`], () => User.get(session?.data?.user?.id), {
    enabled: !!session?.data?.user?.id,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    onSuccess: (d) => {
      const settings = d?.payload?.representation?.attributes?.[`trackUpSettings_${key}`];
      if (settings) {
        // console.log(settings);
        const parsed = JSON.parse(settings);
        // Ensure that settings are in correct format
        const atts = updateLegacySettings(key, parsed, defaultValues);
        const newSettings = {};
        Object.keys(defaultValues)?.forEach?.((key) => {
          if (typeof defaultValues[key] === 'string') {
            newSettings[key] = atts[key] ?? defaultValues[key];
          } else {
            newSettings[key] = { ...defaultValues[key], ...atts[key] };
          }
        });
        setSettings(newSettings);
        setSavedSettings(JSON.stringify(newSettings));
      }
      setIsLoaded(true);
    },
    onError: () => {
      setIsLoaded(true);
      // notify({
      //   message: <FormattedMessage defaultMessage="Failed to fetch user data" description="User data error message" />,
      // });
    },
  });

  useEffect(() => {
    if (userSettings.isFetching) {
      setIsLoaded(false);
    }
  }, [userSettings.isFetching]);

  return { settings, setSettings, saveChanges, undoChanges, isLoaded };
}

export default useUserSettings;
