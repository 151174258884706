/** @format */

import { CountryClient } from '@common/model/apiClient';
import { useQuery } from 'react-query';

function useGetCountryById(countryId: string) {
  return useQuery(['country_with_id', countryId], () => CountryClient.getById(countryId), {
    refetchOnWindowFocus: false,
    refetchInterval: false,
    keepPreviousData: true,
    retry: 1,
  });
}

export default useGetCountryById;
