/** @format */
import { cssVars } from '@atoms/GlobalStyles';
import ListBox from '@atoms/Select/ListBox';
import Popover from '@atoms/Popover';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode, useRef, useState } from 'react';
import { AriaSelectProps, HiddenSelect, useButton, useFocusRing, useSelect, mergeProps } from 'react-aria';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Item, useSelectState } from 'react-stately';
import { FormattedMessage } from 'react-intl';

interface ButtonProps {
  isOpen?: boolean;
  isFocusVisible?: boolean;
}

export interface ISelectOption {
  id: string;
  name: string;
  options?: Array<ISelectOption>;
}

export const SelectButton = styled.button<ButtonProps>`
  font-family: 'Proxima Nova', Roboto, sans-serif;
  appearance: none;
  background: ${(props) => (props.isOpen ? cssVars.lightBlue : 'white')};
  padding: 2px 2px 2px 8px;
  outline: none;
  border: 1px solid ${(props) => (props.isFocusVisible ? cssVars.blue : 'lightgray')};
  box-shadow: ${(props) => (props.isFocusVisible ? `0 0 0 3px ${cssVars.blue}33` : '')};
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  min-width: 180px;
  text-align: left;
  font-size: 14px;
  color: black;
  cursor: pointer;
  box-sizing: border-box;
`;

export const IconWrapper = styled.span`
  > svg {
    width: 18px;
    height: 18px;
  }
  padding: 4px;
  //background: ${cssVars.lightBlue};
  //height: 100%;
  border-radius: inherit;
`;

export const Value = styled.span`
  display: inline-flex;
  align-items: center;
`;

export const StyledSelect = styled.div`
  display: inline-flex;
  font-family: 'Proxima Nova', Roboto, sans-serif;
  //padding-inline: 4px;
  flex-direction: column;
  position: relative;
  margin-inline: 3px;
`;

export const Label = styled.label`
  display: block;
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
`;

function Select<T extends object>(props: AriaSelectProps<T>) {
  // Create state based on the incoming props
  const state = useSelectState(props);

  // Get props for child elements from useSelect
  const ref = useRef(null);
  const { labelProps, triggerProps, valueProps, menuProps } = useSelect(props, state, ref);

  // Get props for the button based on the trigger props from useSelect
  const { buttonProps } = useButton(triggerProps, ref);

  const { focusProps, isFocusVisible } = useFocusRing();

  return (
    <StyledSelect>
      <Label {...labelProps}>{props.label}</Label>
      <HiddenSelect state={state} triggerRef={ref} label={props.label ?? 'Select an option'} name={props.name} />
      <SelectButton {...mergeProps(buttonProps, focusProps)} ref={ref} isOpen={state.isOpen} isFocusVisible={isFocusVisible}>
        <Value {...valueProps}>
          {state.selectedItem ? state.selectedItem.rendered : <FormattedMessage defaultMessage="Select An Option…" description="default option placeholder" />}
        </Value>
        <IconWrapper>
          <FontAwesomeIcon icon={['far', 'chevron-down']} />
        </IconWrapper>
      </SelectButton>
      {state.isOpen && (
        <Popover state={state} triggerRef={ref} placement="bottom start" minWidth={ref?.current?.clientWidth}>
          <ListBox {...menuProps} state={state} />
        </Popover>
      )}
    </StyledSelect>
  );
}

export default Select;
